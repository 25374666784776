import * as React from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from 'gatsby'

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  return (
    // style={{ backdropFilter: 'brightness(25%)', backgroundImage: `url(${bcg})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
    <div className="pb-3 relative xl:h-full" >
      {/* <div > */}
      <StaticImage class="absolute" src='../images/bcg.png' style={{ position: 'absolute !important', width: '100%', height: '100%', objectFit: 'cover', zIndex: 0, opacity: 1, filter: 'brightness(25%)' }} />
      {/* </div> */}

      <div className='container mx-auto px-4 z-10 relative pt-4' style={{ maxWidth: '980px' }}>
        <Helmet>
          <title>Powerlines</title>
          <meta name='description' content='Poskytujeme inžinierske činnosti pre líniové stavby a v oblasti projekcie elektrických vedení VN a VVN' />
          <meta property="og:title" content="Powerlines" />
          <meta property="og:url" content="https://powerlines.sk" />
          <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}/og.jpg`} />
        </Helmet>
        <header className='p-5 rounded bg-slate-700 border border-slate-600 bg-opacity-50'>
          {/* <h1 className='font-bold text-4xl text-white mb-5'>Powerlines</h1> */}
          <div className='md:flex md:gap-3 items-center'>
            <div className="md:w-2/3">
              <div className="flex justify-center">
                <StaticImage src='../images/logo-white-rect.png' className="block w-40 mb-5" placeholder="blurred" />
              </div>
              <p className='text-white'>
                Sme nová spoločnosť
                poskytujúca projekčné a inžinierske činnosti pre líniové stavby a statiku stavieb
                predovšetkým v oblasti projekcie  elektrických vedení VN a VVN.
                Radi sa prispôsobíme požiadavkám zákazníka.
              </p>
            </div>
            <address className='not-italic md:border-l border-slate-500 md:w-1/3 relative p-3 text-white text-sm'>
              POWER LINES, a. s.<br />
              Rybné námestie 1<br />
              811 02 Bratislava - mestská časť Staré Mesto<br />
              Tel. kontakt: <a className="underline" href="tel:+421905432025">+421 905 432 025</a><br />Email: obchod@powerlines.sk<br />
              IČO: 54265851<br />
              DIČ: 2121617311
            </address>
          </div>
        </header>

        <div className='my-14'></div>

        <h2 className='font-bold text-2xl text-center mb-5 text-white'>Poskytujeme komplexné služby</h2>

        <p className='w-3/4 mx-auto text-white text-center'>
          Pre vlastníkov a prevádzkovateľov distribučných sústav v <strong>elektroenergetike</strong> v Slovenskej a Českej republiky,
          Investorov <strong>developerských projektov</strong>, projektov technickej infraštruktúry s vyvolanými investíciami týkajúcich sa úprav <strong>elektrických</strong> vedení.
        </p>

        <div className='my-8'></div>

        <div className='flex gap-5 flex-col md:flex-row text-center'>
          <div className='p-4 md:w-1/3 rounded border border-slate-600 bg-slate-700  bg-opacity-50 text-white shadow-md'>
            <svg class="h-10 w-10 text-white mx-auto mb-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6-6a6 6 0 0 1 -8 -8l3.5 3.5" /></svg>
            <h3 className='font-semibold text-center text-xl mb-2'>Technická pomoc</h3>

            <ul className='flex flex-col gap-2'>
              <li>Dokumentácia pre územné rozhodnutie</li>
              <li>Dokumentácia pre stavebné povolenie</li>
              <li>Dokumentácia pre realizáciu stavby</li>
              <li>Výrobno-montážna dokumentácia</li>
              <li>Dokumentácia skutočného vyhotovenia</li>
            </ul>

          </div>
          <div className='p-4 md:w-1/3 rounded border border-slate-600 bg-slate-700 bg-opacity-50 text-white'>
            <svg class="h-10 w-10 text-white mx-auto mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>


            <h3 className='font-semibold text-center text-xl mb-2'>Exekutíva</h3>

            <ul className='flex flex-col gap-2'>
              <li>Spolupráca pri tvorbe technických špecifikácií a iných </li>
              <li>Výkon technického dozoru investora stavby</li>
              <li>Spolupráca pri kolaudačnom konaní</li>
            </ul>

          </div>
          <div className='p-4 md:w-1/3 rounded border border-slate-600 bg-slate-700 bg-opacity-50 text-white'>
            <svg class="h-10 w-10 text-white mx-auto mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>


            <h3 className='font-semibold text-center text-xl mb-2'>Zabezpečenie povolení</h3>

            <ul className='flex flex-col gap-2'>
              <li>Zabezpečenie súhlasov, rozhodnutí, stanovísk, záväzných stanovísk pre vydanie povolení</li>
              <li>Zabezpečenie právoplatného dokumentu pre stavbu: územné rozhodnutie, stavebné povolenie</li>
              <li>Zabezpečenie geometrických dokumentov</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

// export default IndexPage
